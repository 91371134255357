import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import absenceService from '../../service/absence.service';

import { ButtonUI, DatePickerUI, DialogUI } from '../Interface';
import AbsenceEmptyDaysTable from './AbsenceEmptyDaysTable';

const AbsenceDialog = ({ open, handleClose, user, setRefetchTable }) => {
  const [value, setValue] = useState(null);
  const [formData, setFormData] = useState([]);
  const [month, setMonth] = useState('');
  const [refetch, setRefetch] = useState(true);

  const handleChange = (newValue) => {
    if (newValue) {
      const selectedMonth = new Date(newValue);
      const selectedMonthName = dayjs(selectedMonth).format('YYYY-MM');
      setMonth(selectedMonthName);
      setValue(newValue);
    } else {
      setMonth('');
      setValue(null);
      setFormData([]);
    }
  };

  useEffect(() => {
    if (refetch && month) {
      allEmptyDays();
    }
  }, [refetch]);

  const allEmptyDays = () => {
    absenceService.getEmptyDays({ month: month }).then((data) => {
      setFormData(data);
      setRefetch(false);
    });
  };

  const showEmptyDays = () => {
    if (month) {
      allEmptyDays();
    } else {
      addNotification({
        content: 'Nincs kiválasztott hónap!',
        type: NotificationType.WARNING,
      });
    }
  };

  const generateAbsenceForDay = (data) => {
    const insData = {
      userId: data.id,
      mainCategory: 1,
      subCategory: 1,
      createdBy: user.userId,
      startDate: dayjs(data.day).startOf('hour').add(data.day.utcOffset(), 'minute'),
      endDate: dayjs(data.day).startOf('hour').add(data.day.utcOffset(), 'minute'),
      generated: true,
    };

    absenceService.createAbsence(insData).then(() => {
      addNotification({
        content: 'Sikeres rögzítés!',
        type: NotificationType.SUCCESS,
      });
      setRefetch(true);
      setRefetchTable(true);
    });
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent={
        <div className="flex gap-5 items-center -mt-2">
          <div className="flex-0.5">
            <DatePickerUI
              sx={{
                '& .MuiInput-root': {
                  '&:after': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputLabel-standard': {
                  color: '#fff',
                  '&.Mui-focused': {
                    color: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#fff',
                },
              }}
              views={['year', 'month']}
              openTo="month"
              value={value}
              onChange={handleChange}
              label="Hónap kiválasztása"
              format="MMMM"
            />
          </div>
          <ButtonUI
            text="Generálási lista előkészítése"
            size="sm"
            className="bg-success mt-3"
            onClick={showEmptyDays}
          />
        </div>
      }
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <div className="text-center">
          <AbsenceEmptyDaysTable formData={formData} generateAbsenceForDay={generateAbsenceForDay} />
          <ButtonUI text="Bezárás" color="blue-gray" variant="gradient" onClick={handleClose} />
        </div>
      </div>
    </DialogUI>
  );
};

export default AbsenceDialog;
