import React from 'react';
import { ButtonUI } from '../Interface';
import dayjs from 'dayjs';

const AbsenceEmptyDaysTable = ({ formData, generateAbsenceForDay }) => {
  return (
    <div className="mb-5 max-h-[700px]  overflow-y-scroll overflow-x-hidden custom-scrollbar">
      <table className="border w-full">
        <thead className="bg-tableHeaderColor">
          <tr>
            <th className="border p-3">Dolgozó</th>
            <th className="border p-3">Hiányzó napok</th>
          </tr>
        </thead>
        <tbody>
          {formData.map((row, index) => (
            <tr key={row.id} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
              <td className="border p-5 align-top text-left w-[300px] text-[1.1rem] font-semibold text-gray-600">
                {row.name}
              </td>
              <td className="border text-left p-5 font-semibold text-gray-600">
                <div className="grid grid-cols-2 gap-2">
                  {row.missingDays.map((day, dayIndex) => (
                    <React.Fragment key={dayIndex}>
                      <div className="col-span-1 mt-1">{day}</div>
                      <div className="col-span-1">
                        <ButtonUI
                          className="bg-success "
                          text="Szabadság rögzítés"
                          size="sm"
                          onClick={() => generateAbsenceForDay({ id: row.id, day: dayjs(day) })}
                        />
                      </div>

                      {dayIndex < row.missingDays.length - 1 && (
                        <div className="col-span-2 border-b border-gray-300"></div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AbsenceEmptyDaysTable;
