import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'absence';

const absenceService = {
  getAbsences: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/absences`,
      data,
    });
  },

  createAbsence: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateAbsence: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteAbsence: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  getEmptyDays: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/emptyDays`,
      data,
    });
  },
};

export default absenceService;
